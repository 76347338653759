
export default {
  name: "SwiperStore",

  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      imageMockup: "/assets/images/home/store-slider/laptop-mockup.png",
      slides: [
        {
          image: "/assets/images/home/store-slider/store-7.jpg",
          title: this.$t('storeTitle1'),
          paragraph: this.$t('storeParagraph1'),
          linkStore: "https://rahatystore.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-1.jpg",
        //   title: "متجر قناطير",
        //   paragraph: "متجر لبيع القهوة والشوكولاته يمكنك زياته من هنا",
        //   linkStore: "https://qanateer.cafe/",
        // },
        {
          image: "/assets/images/home/store-slider/store-2.jpg",
          title: this.$t('storeTitle2'),
          paragraph: this.$t('storeParagraph2'),
          linkStore: "https://little-rosa.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-3.jpg",
        //   title: "متجر الدهناء",
        //   paragraph: "المتجر الخاص بمنتجات العلامة التجارية شاي الدهناء",
        //   linkStore: "https://aldhna.matjrah.store/",
        // },
        {
          image: "/assets/images/home/store-slider/store-16.png",
          title: this.$t('storeTitle3'),
          paragraph: this.$t('storeParagraph3'),
          linkStore: "https://shubra.online/"
        },
        // {
        //   image: "/assets/images/home/store-slider/store-17.png",
        //   title: "ذا لايك",
        //   paragraph: "أحدث ستايلات الملابس الشبابية والنسائية في متجر ذا لايك",
        //   linkStore: "https://thelikesa.com/"
        // },
        {
          image: "/assets/images/home/store-slider/store-18.png",
          title: this.$t('storeTitle4'),
          paragraph: this.$t('storeParagraph4'),
          linkStore: "https://attar-sa.com/"
        },
        {
          image: "/assets/images/home/store-slider/store-4.jpg",
          title: this.$t('storeTitle5'),
          paragraph: this.$t('storeParagraph5'),
          linkStore: "https://weaver-design.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-5.jpg",
          title: this.$t('storeTitle6'),
          paragraph: this.$t('storeParagraph6'),
          linkStore: "https://lareinapetite.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-6.jpg",
          title: this.$t('storeTitle7'),
          paragraph: this.$t('storeParagraph7'),
          linkStore: "https://majestya.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-8.jpg",
          title: this.$t('storeTitle8'),
          paragraph: this.$t('storeParagraph8'),
          linkStore: "https://mirza.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/store-9.jpg",
          title: this.$t('storeTitle9'),
          paragraph: this.$t('storeParagraph9'),
          linkStore: "https://dkoo-store.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-10.jpg",
          title: this.$t('storeTitle10'),
          paragraph: this.$t('storeParagraph10'),
          linkStore: "https://fath-int.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-11.jpg",
          title: this.$t('storeTitle11'),
          paragraph: this.$t('storeParagraph11'),
          linkStore: "http://kanzalsahra.com",
        },
        {
          image: "/assets/images/home/store-slider/store-12.jpg",
          title: this.$t('storeTitle12'),
          paragraph: this.$t('storeParagraph12'),
          linkStore: "https://fishshopest.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/store-15.jpg",
          title: this.$t('storeTitle13'),
          paragraph: this.$t('storeParagraph13'),
          linkStore: "https://abayatallamsa.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-13.jpg",
          title: this.$t('storeTitle14'),
          paragraph: this.$t('storeParagraph14'),
          linkStore: "https://mrpc-ksa.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-14.jpg",
        //   title: "كيوت كاتس",
        //   paragraph:
        //     "تجر للقطط يطمح للتكامل بتوفير جميع الماركات العالمية في مكان واحد",
        //   linkStore: "https://cutecats-pets.com/",
        // },
      ],
      slidesKW: [
        {
          image: "/assets/images/home/store-slider/kw-store-1.png",
          title: this.$t('storeTitleKW1'),
          paragraph: this.$t('storeParagraphKW1'),
          linkStore: "https://darhorof.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-2.png",
          title: this.$t('storeTitleKW2'),
          paragraph: this.$t('storeParagraphKW2'),
          linkStore: "https://khatwahkw.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-3.png",
          title: this.$t('storeTitleKW3'),
          paragraph: this.$t('storeParagraphKW3'),
          linkStore: "https://boxaqra.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-4.png",
          title: this.$t('storeTitleKW4'),
          paragraph: this.$t('storeParagraphKW4'),
          linkStore: "https://alwardibookshop.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-5.png",
          title: this.$t('storeTitleKW5'),
          paragraph: this.$t('storeParagraphKW5'),
          linkStore: "https://abayatalamsa.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-6.png",
          title: this.$t('storeTitleKW6'),
          paragraph: this.$t('storeParagraphKW6'),
          linkStore: "https://fishshopest.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-7.png",
          title: this.$t('storeTitleKW7'),
          paragraph: this.$t('storeParagraphKW7'),
          linkStore: "https://weaver-design.com/",
        },
      ],
    };
  },
  mounted() {
    if (window.innerWidth > 960) {
      this.swiperOption.autoplay = { delay: 5000 };
    }
    if (this.$i18n.locale == 'kw') {
      this.slidesKW.map(slide => {
        this.slides.push(slide)
      })
    }
  },
};
